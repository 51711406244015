import type { Locale } from "@commerce-frontend/i18n/types";
import { fallbackLocale } from "@commerce-shared/site-config";
import type { SiteConfig, StoreConfig, StoreKey } from "~/lib/store-config/context";

export const siteUrls = {
	development: "http://localhost:3000",
	tst: "https://www.tst.zeeman.io",
	prd: "https://www.zeeman.com",
};

export const siteConfig: SiteConfig = {
	url: siteUrls[(process.env.ENVIRONMENT as keyof typeof siteUrls) ?? "prd"],
	defaultLocale: fallbackLocale as Locale,
	locales: ["nl-NL", "nl-BE", "fr-BE", "fr-FR", "de-DE", "fr-LU", "de-AT", "es-ES", "pt-PT"],
};

export const storeConfigs: Record<StoreKey, StoreConfig> = {
	NL: {
		country: "NL",
		currency: "EUR",
		defaultLocale: "nl-NL",
		locales: ["nl-NL"],
		storeKey: "NL",
		timezone: "Europe/Amsterdam",
		creditCardBrands: ["mc", "visa"],
		metadata: {
			siteName: "Zeeman",
			tagline: {
				"nl-NL": "Zo eenvoudig kan het zijn.",
			},
			productionUrl: siteConfig.url,
			keywords: [""],
		},
		etrustedEnabled: true,
	},
	BE: {
		country: "BE",
		currency: "EUR",
		defaultLocale: "nl-BE",
		locales: ["nl-BE", "fr-BE"],
		storeKey: "BE",
		timezone: "Europe/Amsterdam",
		creditCardBrands: ["bcmc", "maestro", "mc", "visa"],
		metadata: {
			siteName: "Zeeman",
			tagline: {
				"nl-BE": "Zo eenvoudig kan het zijn.",
				"fr-BE": "C’est aussi simple que cela.",
			},
			productionUrl: siteConfig.url,
			keywords: [""],
		},
		etrustedEnabled: true,
	},
	FR: {
		country: "FR",
		currency: "EUR",
		defaultLocale: "fr-FR",
		locales: ["fr-FR"],
		storeKey: "FR",
		timezone: "Europe/Amsterdam",
		creditCardBrands: ["mc", "visa", "cartebancaire"],
		metadata: {
			siteName: "Zeeman",
			tagline: {
				"fr-FR": "C’est aussi simple que cela.",
			},
			productionUrl: siteConfig.url,
			keywords: [""],
		},
		etrustedEnabled: true,
	},
	DE: {
		country: "DE",
		currency: "EUR",
		defaultLocale: "de-DE",
		locales: ["de-DE"],
		storeKey: "DE",
		timezone: "Europe/Amsterdam",
		creditCardBrands: ["mc", "visa"],
		metadata: {
			siteName: "Zeeman",
			tagline: {
				"de-DE": "So einfach kann es sein.",
			},
			productionUrl: siteConfig.url,
			keywords: [""],
		},
		etrustedEnabled: true,
	},
	LU: {
		country: "LU",
		currency: "EUR",
		defaultLocale: "fr-LU",
		locales: ["fr-LU"],
		storeKey: "LU",
		timezone: "Europe/Amsterdam",
		creditCardBrands: ["mc", "visa"],
		metadata: {
			siteName: "Zeeman",
			tagline: {
				"fr-LU": "C’est aussi simple que cela.",
			},
			productionUrl: siteConfig.url,
			keywords: [""],
		},
		etrustedEnabled: false,
	},
	AT: {
		country: "AT",
		currency: "EUR",
		defaultLocale: "de-AT",
		locales: ["de-AT"],
		storeKey: "AT",
		timezone: "Europe/Amsterdam",
		creditCardBrands: ["mc", "visa"],
		metadata: {
			siteName: "Zeeman",
			tagline: {
				"de-AT": "So einfach kann es sein.",
			},
			productionUrl: siteConfig.url,
			keywords: [""],
		},
		etrustedEnabled: false,
	},
	ES: {
		country: "ES",
		currency: "EUR",
		defaultLocale: "es-ES",
		locales: ["es-ES"],
		storeKey: "ES",
		timezone: "Europe/Amsterdam",
		creditCardBrands: ["mc", "visa"],
		metadata: {
			siteName: "Zeeman",
			tagline: {
				"es-ES": "Tan sencillo puede ser.",
			},
			productionUrl: siteConfig.url,
			keywords: [""],
		},
		etrustedEnabled: true,
	},
	PT: {
		country: "PT",
		currency: "EUR",
		defaultLocale: "pt-PT",
		locales: ["pt-PT"],
		storeKey: "PT",
		timezone: "Europe/Amsterdam",
		creditCardBrands: ["mc", "visa"],
		metadata: {
			siteName: "Zeeman",
			tagline: {
				"pt-PT": "Tão simples que pode ser.",
			},
			productionUrl: siteConfig.url,
			keywords: [""],
		},
		etrustedEnabled: false,
	},
};
